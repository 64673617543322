// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from "react"
import { graphql } from "gatsby"
import Layout from "../../src/components/layout-page"
import { domain } from "../../src/constants"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import useQueryThemeOptions from "../../src/hooks/use-query-themeOptions"
import TextEditor from '../../src/blocks/TextEditor'

const PageTemplate = pageProps => {
  
  const { logo } = useQueryThemeOptions().wp.themeOptions.themeOptions || {}

  let components

    components = pageProps.data?.wpPage['pageComponents']['pageComponents'] || []
  
  components = components.map(component => {
    return {
      name: component.__typename.split("_").pop(),
      data: component,
    }
  })

  const {
    title,
    uri,
    featuredImage,
    seo,
  } = pageProps?.data?.wpPage || {}
  const {
    hash
  } = pageProps?.location?.hash || {}
  const slug = uri.replace(/\//g, "");
  return (
    <Layout  slug={slug || 'home'}>
      <GatsbySeo
        title={seo?.title || title}
        slug={slug || 'home'}
        description={seo?.metaDesc}
        titleTemplate="%s"
        openGraph={{
          title: seo?.title || title,
          description: seo?.metaDesc,
          url: domain + uri,
          images: [{ url: featuredImage?.node?.sourceUrl || logo?.sourceUrl }],
        }}
      />
      {components.map((component, index) => {

      if (component.name == 'TextEditor') {
        return <TextEditor {...component.data} key={index} />
      }
    
        return <div>Error: The component {component.name} was not found</div>
      })}
    </Layout>
  )
}

export default PageTemplate


    export const query = graphql`
      query PageQuery3($id: String!) {
        wpPage(id: {eq: $id}) {
          title
          uri
          seo {
            title
            metaDesc
            opengraphType
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          
    pageComponents {
      pageComponents {
        __typename
         
 
      ... on WpPage_Pagecomponents_PageComponents_TextEditor {
        ...Page_TextEditor
      }
    
      }
    }
  
        }
      }
    `
  
