import React from "react"
import { graphql } from "gatsby"
// import parse from "html-react-parser"

import Layout from "../components/layout-page"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import BlogBlock from "../components/acf/blog"
import useQueryThemeOptions from "../hooks/use-query-themeOptions"
import { domain } from "../constants"
const LIMIT_POSTS_PER_PAGINATION = 6

const PageTemplate = ({ data, pageContext, ...props }) => {
  console.dir(data.wpCategory)
  const { logo } = useQueryThemeOptions().wp.themeOptions.themeOptions || {}

  const {
    id,
    name: categoryName,
    description,
    posts,
    uri,
    anotherFields,
    seo,
  } = data.wpCategory || {}

  // ==============================================================
  const [currentPage, setCurrentPage] = React.useState(0)
  const totalPage = Math.ceil(posts.nodes.length / LIMIT_POSTS_PER_PAGINATION)

  return (
    <Layout>
      <GatsbySeo
        title={seo.title || categoryName}
        titleTemplate="%s"
        description={seo.metaDesc || description}
        openGraph={{
          type: seo.opengraphType,
          title: seo.title || categoryName,
          description: seo.metaDesc || description,
          url: domain + uri.replace("/category/", "/blog/"),
          images: [{ url: logo?.sourceUrl }],
        }}
      />
      <div className="container mx-auto px-2 mt-[48px] ">
        <div className="pb-[60] border-b-2 border-solid  mb-6">
          <h1
            className="content mb-1"
            dangerouslySetInnerHTML={{ __html: anotherFields.heading }}
          />
          <div
            className="content mb-5"
            dangerouslySetInnerHTML={{ __html: anotherFields.description }}
          />
        </div>
      </div>

      {/* === MORE POSTS === */}
      {/* List posts of sub category */}
      {posts.nodes?.length ? (
        <>
          <BlogBlock
            blogCount={6}
            recentPosts={posts.nodes.slice(
              currentPage * LIMIT_POSTS_PER_PAGINATION,
              (currentPage + 1) * LIMIT_POSTS_PER_PAGINATION
            )}
          />
          <></>
          <div className="container mx-auto px-2 my-6">
            <ul className="pagination flex justify-center gap-4">
              <li
                role="presentation"
                className={`page-item  cursor-pointer user-select-none ${
                  currentPage === 0 ? "disabled" : ""
                }`}
                onClick={() =>
                  currentPage ? setCurrentPage(currentPage - 1) : ""
                }
              >
                <span className="page-link">Previous</span>
              </li>
              {Array.from({ length: totalPage }).map((_, index) => (
                <li
                  key={index}
                  className={` page-item ${
                    currentPage === index ? "active" : "cursor-pointer "
                  }`}
                  onClick={() => setCurrentPage(index)}
                  role="presentation"
                >
                  <span
                    className={`page-link ${
                      currentPage === index ? "text-blue-100" : ""
                    }`}
                  >
                    {index + 1}
                  </span>
                </li>
              ))}
              <li
                role="presentation"
                className={` page-item cursor-pointer user-select-none ${
                  currentPage + 1 >= totalPage ? "disabled" : ""
                }`}
                onClick={() =>
                  currentPage + 1 < totalPage
                    ? setCurrentPage(currentPage + 1)
                    : ""
                }
              >
                <span className="page-link">Next</span>
              </li>
            </ul>
          </div>
        </>
      ) : null}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query queryCategory($slug: String!) {
    wpCategory(slug: { eq: $slug }) {
      id
      name
      uri
      slug
      parentId
      description
      seo {
        title
        metaDesc
        opengraphType
      }
      posts {
        nodes {
          ...postFragment
        }
      }
      anotherFields {
        description
        heading
        type
        postFeatured {
          ...postFragment
        }
      }
    }
  }
`
